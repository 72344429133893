.header-img {
    height: 100%;
    font-family: 'Blippo Regular';
    font-weight: 600;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    font-size: 2em;
    color: #ffff;
    list-style: none;
}
img {
    max-width: 140px;
}
.header-img-span{

}
