

.header-sign {
    height: 100%;
    max-width: 120px;
    display: flex;
    align-items: center;
    justify-content: flex-end; }
.header-sign .header-button {
    font-weight: 700;
    font-size: 1em;
    text-decoration: none;
    padding: 10px 15px;
    cursor: pointer;
    display: flex;
    vertical-align: middle;
}
.header-sign .header-button--gooey {
    color: #80E081;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 4px solid #80E081;
    border-radius: 8px;
    position: relative;
    transition: all 700ms ease; }
.header-sign .header-button--gooey .header-button__blobs {
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: -3px;
    right: -1px;
    z-index: -1; }
.header-sign .header-button--gooey .header-button__blobs div {
    background-color: #80E081;
    width: 36%;
    height: 100%;
    border-radius: 100%;
    position: absolute;
    transform: scale(1.4) translateY(125%) translateZ(0);
    transition: all 400ms ease; }
.header-sign .header-button--gooey .header-button__blobs div:nth-child(1) {
    left: -5%; }
.header-sign .header-button--gooey .header-button__blobs div:nth-child(2) {
    left: 30%;
    transition-delay: 60ms; }
.header-sign .header-button--gooey .header-button__blobs div:nth-child(3) {
    left: 66%;
    transition-delay: 25ms; }
.header-sign .header-button--gooey:hover {
    color: #fff; }
.header-sign .header-button--gooey:hover .header-button__blobs div {
    transform: scale(1.4) translateY(0) translateZ(0); }