@import '../variable/variable';

.footer {
    overflow: hidden;
    // margin-top: 100px;
    width: 100%;
    background: #454541;
    min-height: 50vh;
}
.footer-container {
    font-family: sans-serif;
    max-width: 1400px;
    min-height: 50vh;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    @media screen and (max-width: 600px) {
        display: flex;
        flex-flow: row wrap;
    }
    &__column {
        width: 400px;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: space-evenly;
        color: #D4D4CF;
        cursor: default;
        margin: 1em 0.5em;

        .column-item {
            font-size: 1.1em;
            width: 50%;
            display: flex;
            justify-content: space-evenly;
        }
        .column-item:last-of-type {
            margin-top: 1em;
        }
        
    }
    .column-map {
        height: 350px;
        // border-right: 1px solid rgba(255,255,255,0.2);
        filter: grayscale(50%);
        iframe {
            border-radius: 5px;
            opacity: 0.8;
        }
        padding-right: 2em;
        @media screen and (max-width: 1265px) {
            // border-right: 0;
            
        }
        @media screen and (max-width: 770px) { 
            // border-bottom: 1px solid rgba(255,255,255,0.2);
            padding: 2em 0;
        }
    }
    .column-about{
        // border-left: 1px solid rgba(255,255,255,0.2);
        height: 300px;

        @media screen and (max-width: 1265px) {
            // border-top: 1px solid rgba(255,255,255,0.2);
            border-left: 0;
        }
        @media screen and (max-width: 770px) {
            // border-top: 1px solid rgba(255,255,255,0.2);
            padding-top: 2em;
            width: 100%;
        }
        h2 {
            font-size: 1em;
            margin-bottom: 1em;
            text-align: right;
            width: 300px;
            
            @media screen and (max-width: 770px) {
                padding: 0;
                text-align: right;
                // background: rosybrown;
                width: 260px;
            }
        }
        p {
            font-size: 0.9em;
            letter-spacing: 0.5px;
            text-align: right;
            padding: 0.5em 1em;
            line-height: 1.4em;
            color: #fff;
            @media screen and (max-width: 770px) {
                padding: 0;
                font-size: 0.8em;
                text-align: right;
            }
        }
    }
    .column-contact {
        
        height: 300px;
        h2 {
            font-size: 1em;
            width: 200px;
            // background: rosybrown;
            text-align: right;
            margin-bottom: 1em;
            @media screen and (max-width: 770px) {
                padding: 0;
                text-align: right;
                // background: rosybrown;
                width: 170px;
            }
        }
        a {
            // text-align: right;
            font-size: 0.9em;
            display: flex;
            justify-content: flex-end;
            width: 200px;
            text-decoration: none;
            color: #fff;
            margin-right: 1em;
        }
        
        .item-container-icon {
            width: 200px;
            display: flex;
            justify-content: flex-end;
        }
        .item-container {
            margin-top: 1em;
            display: flex;
            justify-content: space-evenly;
            @media screen and (max-width: 770px) {
                padding: 0;
                font-size: 0.8em;
                margin-right: 2em;
            }
            .icon-item {
                text-decoration: none;
                // color: #573d16;
                color: #fff;
                margin-right: 0em;
                display: flex;
                justify-content: flex-end;
                a {
                    max-width: 2em;
                    margin: 0;
                    margin-left: 1em;
                }
            }
            // background: chartreuse;
            i {
                font-size: 1.5em;
                
                
                
            }

        }
    }
}
@media screen and(max-width: 1000px){
    .footer{
        .footer-container{
            padding: 0 10px;
        }
    }
}
