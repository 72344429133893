@import '../../../variable/variable';

.main-acordion {
    // z-index: 1;
    width: 100%;
    height: 100vh;
    position: relative;
    // margin-top: 80px;
    margin-bottom: 100px;
    .acordion-container{
        
        display: flex;
        justify-content: space-between;
        height: 100vh;
        // overflow: hidden;  
        &__item {
            width: 100%;
            // opacity: 0.7;
            transition: 0.5s;

        }
        .item-one{
            background: url('./lime.jpg') center center no-repeat;
            background-size: cover;
        }
        .item-two{
            background: url('./mandarin.jpg') center center no-repeat;
            background-size: cover;
        }
        .item-three{
            background: url('./lemon.jpg') center center no-repeat; 
            background-size: cover;
            
        }
        .item-four{
            background: url('./grapefruit.jpg') center center no-repeat;
            background-size: cover;
        }
        // .item-five{
        //     background: url('./4.png') center center no-repeat;
        //     background-size: cover;
        // }
        .inner__block {
            opacity: 0;
            height: 100%;
            transition: 0.5s;
            display: flex;
            flex-flow: column-reverse;
            cursor: pointer;
            .wrapper {
                // background: rgba(255,255,255, 0.4);
                height: 30%;
                display: flex;
                flex-flow: column;
                align-items: flex-end;
                justify-content: space-between;
                overflow: hidden;
                
                // padding: 10px 15px;

                @media screen and (max-width: 1030px) {
                    height: 40%;
                    
                }
                @media screen and (max-width: 800px) {
                    height: 70%;
                    width: 100%;
                }
                .inner__block-title {
                    padding: 10px 15px;
                    height: 20%;
                    font-family: sans-serif;
                    font-size: 3em;
                    color: $main-brown-font;
                    transform: translateX(-300%);
                    transition: 0.7s;
                    background: rgba(255,255,255, 0.6s);
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    
                    // margin-top: 30px;
                    // background: blue;
                    @media screen and (max-width: 1030px) {
                        font-size: 2.1em;
                    }
                }
                .inner__block-title:last-of-type{
                    border-bottom: 1px solid rgba(255,255,255, 0.8);
                }
                .inner__block-text {
                    padding: 10px 15px;
                    font: 1.5em sans-serif;
                    letter-spacing: 0.5px;
                    background: rgba(255,255,255, 0.6s);
                    width: 100%;
                    // text-align:end;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    transform: translateY(100%);
                    transition: 0.7s;
                    justify-content: flex-end;
                    text-align: right;
                    @media screen and (max-width: 600px) {
                        max-width: 100%;
                        font-size: 1.1em;
                    }
                    @media screen and (max-width: 400px) {
                        max-width: 100%;
                        font-size: 1em;
                    }
                }
                p:hover {
                    
                }

            }
            
        }
        .inner__block:hover {
            opacity: 1;
            .inner__block-text{
                transform: translateY(0);
            }
            .inner__block-title{
                transform: translateX(0);
            }
        }
        

        &__item:hover {
            // animation: animatezoom 0.7s;
            // border: 1px solid #000000;
            
            width: 300%;
            opacity: 1;
            box-shadow: 3px -8px 25px 9px rgba(37, 60, 53, 0.33);
            z-index: 2;
            
        }
    }
}

@media screen and (max-width: 805px) {
    .main-acordion {
        .acordion-container {
            flex-flow: column;
            &__item {
                // height: 100%;
                height: 25vh;

            }
            &__item:hover {
                height: 35vh;
                width: 100%;
            }
        }
    }
}

@keyframes animatezoom {
    // from {transform: scale(0)} 
    // to {transform: scale(0)}
}