* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
::-webkit-input-placeholder {
  text-align:right;
  font-size: 1em;
  }
::-webkit-scrollbar {
  /* width: 1em; */
  /* background-color: transparent; */
}  
li, a {
  text-decoration: none;
  list-style-type: none;
  }
input {
  font-size: 1em
  
}

/* clear style from uphone */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

body {
  margin: 0;
  /* min-height: 100%; */
  /* background: url('./components/main/welcompage/newRegForma/bb.jpg') fixed center center no-repeat;
  background-size: cover; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: 'Blippo Regular';
  
  src: local('Blippo Regular'),
  url(./fonts/BlippoRegular.ttf) format('truetype'),
  url(./fonts/BlippoRegular.woff) format('woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
details {
  text-decoration: none;
  list-style-type: none;
  
  
}
summary {outline: none;}
summary::-webkit-details-marker {display: none;}