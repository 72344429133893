

.header-nav {
    height: 100%;
    width: 500px; }
.header-nav__list {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
.header-nav__list-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; }
    .header-nav__list-item .nav-links {
    height: 80%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.1s;
    font-family: "Roboto", sans-serif;
    /* color: #573d16; */
    font-size: 19px;
    letter-spacing: 1px;
    text-transform: uppercase; }
.header-nav__list-item .nav-links:hover {
    font-weight: 700;
    color: #fff !important;
    background: #80e081;
    border-radius: 8px; }
    

    @media screen and (max-width: 800px) {
          .header .header-container {
            padding: 0 10px; }
            .header .header-container .header-nav {
              display: none; }
             }