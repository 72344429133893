.stat {
    display: none;
    height: 40vh;
    margin: 0 auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
    user-select: none;
    background: #ffc700;
    
    padding: 0 8rem;
    margin-bottom: 100px;
    @media screen and (max-width: 600px) {
        padding: 0 ;
        height: auto;
        padding-top: 2em;
    }
    .stat-container {
        width: 30%;
    //    background: #573d16;
        height: 50%;
        display: flex;
        flex-flow: column;
        align-items: center;
        
        justify-content: space-evenly;
        @media screen and (max-width: 600px) {
            height: 120px;
            width: 80%;
            margin-bottom: 2.5em;
            display: flex;
            // flex-flow: row-reverse wrap;
            flex-flow: row-reverse;
            // grid-template-columns: 100%;
            // grid-template-rows: 100%;
            
        }
        h2 {
            color: #1A1918;
            font-size: 1.8em;
            @media screen and (max-width: 600px) {
                font-size: 1.7em;
                text-align: center;
                width: 100%;
                // background: lightblue;
                // grid-column-start: 1;
                // grid-column-end: 3;
                // grid-row-start: 1;
            }
        }
        span {
            width: 40%;
            color: #1A1918;
            font-size: 3em;
            @media screen and (max-width: 600px) {
                font-size: 2em;
                display: flex;
                justify-content: flex-start;
               
                // background: lemonchiffon;
                display: none;
                // padding-right: 1em;
            }
        }
        .fa-user:hover {
            border-radius: 50%;
            animation: pulse 1s ease infinite;
            
        }
        .fa-cog:hover {
            animation: spin 5s linear 0s infinite normal;
        }
        .fa-calendar-check:hover {
            animation: rubberBand 0.6s ease;
        }
        &__item {
            
            color: #1A1918;
            font-size: 2em;
            font-weight: 600;
            letter-spacing: 1px;
            user-select: none;
            @media screen and (max-width: 600px) {
                font-size: 3em;
                display: flex;
                justify-content: center;
                width: 100%;
                // padding-left: 0.3em;
            }
        }
    }
}
@keyframes spin {
    0% { transform: rotate(360deg); }
    
    100% { transform: rotate(0deg); }
  }

@keyframes rubberBand {
  0% {transform: scale(1);}
  30% {transform: scaleX(1.15) scaleY(0.75);}
  40% {transform: scaleX(0.75) scaleY(1.25);}
  60% {transform: scaleX(1.15) scaleY(0.85);}
  100% {transform: scale(1);}
} 

@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(0,0,0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0,0,0, 0);
    }
  }