@import '../../variable/variable.scss';

.wrapper-menu {
    // position: fixed;
    // height: 100%;
    // width: 100%;

    .wrapper-container {
        display: flex;
        // height: 100%;
        // width: 100%;
        // background: $main-white-hovertext;
        // justify-content: space-between;
        // flex-flow: row-reverse;
        
        &__humbMenu {
            // margin-left: 85%;
            // margin-top: 10px;
            height: 40px;
            width: 40px;
            display: none;
            align-items: center;
            justify-content: center;
            flex-flow: column;
            border-radius: 50%;
            z-index: 10;
            position: fixed;
            right: 0.3em;
            top: 0.3em;
            display: flex;
            background: #fff;
            -webkit-box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1); 
            box-shadow: 0px 1px 2px 2px rgba(161,161,161,0.1);
            @media screen and (max-width: 400px) {
                background: none;
            }

            &-btn {
                display: block;
                transform-origin: 16px 11px;
                position: absolute;
                background: none;
                height: 100%;
                width: 30px;
                z-index: 12;
                opacity: 0;
                cursor: pointer;

            }

            // &-span {
            //     display: block;
            //     width: 35px;
            //     height: 5px;
            //     margin-bottom: 4px;
            //     background-color: $main-green;
            //     border-radius: 8px;
            //     transition: all 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
            // }

            // &-btn:active~ {
            //     .wrapper-container__humbMenu-span:nth-of-type(1) {
            //         width: 23px;
            //     }

            //     .wrapper-container__humbMenu-span:nth-of-type(2) {
            //         width: 20px;
            //     }

            //     .wrapper-container__humbMenu-span:nth-of-type(3) {
            //         width: 30px;
            //     }
            // }
        }

        &__sidebar {
            background: #574B4B;
            padding-top: 50px;
            height: 100%;
            min-width: 250px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: space-between;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            z-index: 2;

            @media screen and (max-width: 770px) {
                width: 50%;
                position: fixed;
                z-index: 9;
                // border-right: 1px solid #f2f2f2;
            }

            @media screen and (max-width: 600px) {
                transform: translateX(-100%);
                width: 80%;
                border-right: none;
                padding-top: 20px;
                box-shadow: 3px -2px 4px -4px rgba(242,242,242,0.9);
            }

            .wrapper-container__sidebar-menu {
                width: 100%;
                display: flex;
                flex-flow: column;
                overflow: hidden;
                
                .wrapper-container__sidebar-menu-wrapperavatar{
                    display: grid;
                    grid-template-columns: 40% auto;
                    grid-template-rows: 50% 50% ;
                    padding: 0.5em 0;
                    align-items: start;
                    // box-shadow: 1px 21px 2px -20px  #F2F2F2;
                    background: #574B4B;
                }
                .wrapper-container__sidebar-menu-logoname{
                    grid-row-start: 1;
                    padding-top: 1em;
                    padding-left: 3em;
                    color: #fff;
                    
                }
                .wrapper-container__sidebar-menu-avatar {
                    grid-column-start: 2;
                    grid-row-start: 1;
                    
                    min-height: 90px;
                    min-width: 90px;
                    border-radius: 50%;
                    background: $main-white-hovertext;
                    margin: 0 auto;
                    margin-bottom: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    @media screen and (max-width: 770px) {
                        margin-bottom: 20px;

                    }

                }

                .wrapper-container__sidebar-menu__scrollwrapper {
                    // padding-top: 0.5em;
                    overflow-x: hidden;
                    overflow-y: scroll;
                    width: 110%;
                    padding-right: 0.5em;
                    background: #5f5353;
                 
                }

                &__item {
                    overflow: hidden;
                    padding: 10px 10px;
                    color: $main-white-hovertext;
                    font-family: sans-serif;
                    letter-spacing: 1px;
                    transition: 0.2s;
                    position: relative;
                    @media screen and (max-width: 770px) {
                        padding: 0.5em 2em 0.5em 1.5em;

                    }
        


                    .wrapper-container__sidebar-menu__item-wrapper {
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        flex-flow: row;
                        width: 100%;
                        // background: red;
                        margin: 0 auto;

                        &-summary {
                            display: flex;
                            flex-flow: row-reverse;
                            width: 100%;

                            color: $main-brown-font;
                            padding: 5px 5px;
                            background: #a3eba3;
                        }
                    }

                    p {
                        font-size: 14px;
                        font-weight: 600;
                        margin-left: 25px;
                        font-family: sans-serif;
                        text-transform: uppercase;
                        margin-right: 0.5em;
                    }

                    &__inner {
                        height: 40px;
                        width: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        transition: 0.7s;

                        .fa-file-invoice {
                            color: $main-white-hovertext;
                        }
                    }
                }
                
                &__item:hover {
                    // background: #80E081; 
                    background: $main-green;
                    color: $main-white-hovertext;
                    width: 100%;
                    box-shadow: none;

                    // height: 60px;
                    .fa-file-invoice {
                        animation: rubberBand 0.6s ease;
                    }

                    .wrapper-container__sidebar-menu__item__inner {
                        // transform: translateX(-10px);
                        animation: shake 0.7s ease;

                        @keyframes shake {
                            0% {
                                transform: translateX(0);
                            }

                            50% {
                                transform: translateX(-5px);
                            }

                            75% {
                                transform: translateX(3px);
                            }

                            90% {
                                transform: translateX(-2px);
                            }
                        }
                    }
                }
            }

            .wrapper-container__sidebar-menu-control {
                margin-bottom: 20px;
                padding: 20px 0;
                width: 100%;
                display: flex;
                flex-flow: column;
                align-items: center;
                z-index: 0;

                // ! change tag
                &__logo {
                    width: 100%;
                    
                    @media screen and (max-width: 600px) {
                        margin-right: 0.8em;
                    }
                    .modal-form__tag {
                        color: $main-white-hovertext;
                    }
                    .tag-in {
                        color: $main-green;
                    }
                }
                
                @media screen and (max-width: 1300px) {
                    padding: 20px 30px;
                }

                @media screen and (max-width: 600px) {
                  padding: 20px 100px;  

                }
            }
            .wrapper-container__sidebar-menu-control-bottom{
                height: 3em;
                width: 100%;
                // background: chartreuse;
                &__wrapper{
                    background: #5f5353;
                    width: 100%;
                    height: 100%;
                    // background: red;
                    display: flex;
                    justify-content: space-around;
                    &-item{
                        // background: yellow;
                        height: 100%;
                        width: 25%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        
                        i {
                            color: #fff;
                            font-size: 1em;
                            transition: 0.2s;
                            @media screen and (max-width: 600px){
                                font-size: 1.3em;
                            }
                        }
                    }
                    &-item:hover {
                        background: $main-green;
                        i{
                            font-size: 1.2em;
                        }
                    }
                }
            }
        }


        &__mainbar {
            display: flex;
            width: 85%;
            border-radius: 10px;
            height: 100%;

            @media screen and (max-width: 600px) {
                width: 100%;
                position: absolute;
            }
            

            .wrapper-container__mainbar-dbm {
                background: #f3f5f7;
                width: 100%;
                height: 50px;
                border-bottom-left-radius: 10px;
                display: flex;

                .dropdown {
                    display: flex;
                    align-items: center;
                    padding-right: 15px;
                    border-right: 1px solid rgba(87, 61, 22, 0.2);
                    max-width: 450px;

                    &__name {
                        margin-left: 20px;
                        color: $main-brown-font;
                        font-family: sans-serif;
                        letter-spacing: 0.5px;
                        margin-right: 10px;
                        font-size: 1.3em;
                    }

                    &__select {
                        cursor: pointer;
                        outline: none;
                        background: $main-white-hovertext;
                        // border-color: $main-brown-font;
                        border-radius: 8px;
                        border: none;
                        width: 60px;
                        // max-height: 45px;
                        // max-width: 150px;
                        padding: 0.2em 0.7em;
                        border: 1px solid $main-brown-font;
                        appearance: none;
                        transition: 0.3s;

                        // display: none;
                        // background: red;
                        &-option {
                            display: flex;
                            border: none;
                            width: 100%;
                            align-items: center;
                            height: 22px;
                            margin-bottom: 4px;

                        }
                    }

                    &__select:focus {
                        width: 150px;
                    }

                    &__btn {
                        cursor: pointer;
                        padding: 5px 10px;
                        margin-left: 10px;
                        border-radius: 8px;
                        background: $main-white-hovertext;
                        border: 2px solid $main-green;
                        color: $main-green;
                        font-size: 15px;
                        outline: none;
                        letter-spacing: 0.5px;
                    }

                    &__btn:active {
                        transform: scale(1.1);
                    }

                    &__btn:hover {
                        background: $main-green;
                        color: $main-white-hovertext;
                    }
                }
            }
        }
    }
}

@keyframes rubberBand {
    0% {
        transform: scale(1);
    }

    30% {
        transform: scaleX(1.15) scaleY(0.75);
    }

    40% {
        transform: scaleX(0.75) scaleY(1.25);
    }

    60% {
        transform: scaleX(1.15) scaleY(0.85);
    }

    100% {
        transform: scale(1);
    }
}


.second-mainbar {
    background: #f2f2f2;
    // background: red;
    color: $main-brown-font;
    font-family: sans-serif;
    letter-spacing: 0.5px;
    width: 100%;
    // padding: 0 10px 0 10px;
    // height: 100vh;
    // margin: 0 auto;
    // overflow: auto;
    // position: fixed;
    @media screen and (max-width: 770px) {
        width: 100%;
        // position: absolute;
        padding: 0;
    }

    &-wrapper {
        height: 100vh;
        max-width: 1200px;
        margin: 0 auto;

        // background: red;
    }
}