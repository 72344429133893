.header {
    z-index: 99;
    top: 0;
    position: fixed;
    background-color:none;
    width: 100%;
    height: 80px;
    transition: all 0.4s ease;
    
    /* box-shadow:  8px 8px 16px #f0f0f0, 
                -8px -8px 16px #ffffff; */
    /* border-bottom: 1px solid;
    border-color: #C0FACD;  */
}

.header-container {
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center; 
    color: #80e081;
    padding: 0 0.5em;
    
}
@media  screen and (max-width: 1300px) {
    .header-container {
        padding: 0 0.5em;
    }
    
}
