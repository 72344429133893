@import '../../../../variable/variable';



.second-mainbar-wrapper {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  
  &__title {

    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 2em;

    @media screen and (max-width: 600px) {
      // font-size: 14px;
      text-transform: lowercase;
      height: 2em;
    }

  }

  &__calendar {
    background: #574b4b;
    height: 7vh;
    max-width: 1000px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    border-radius: 5px;
    margin-bottom: 10px;
    justify-content: space-between;
    padding: 0 10em;

    @media screen and (max-width: 1300px) {
      margin: 0 1em 10px 1em;
      padding: 0 3em;
    }

    @media screen and (max-width: 770px) {
      max-width: 100%;
      margin: 0 1em 10px;
    }

    @media screen and (max-width: 600px) {
      height: 3.5rem;
      margin: 0 0.2em 10px;
      padding: 0 0.5em;
      ;
      // padding-right: 0 2em;

    }

    &-item {
      
      display: flex;
      justify-content: center;
      position: relative;
      

      @media screen and (max-width: 600px) {
        justify-content: center;

      }

      input {
        background: $main-white-hovertext;
        max-width: 200px;
        border-radius: 5px;
        padding: 0.5em 1em;
        font-size: 15px;
        border: none;
        outline: none;
        text-align: right;

        @media screen and (max-width: 600px) {
          font-size: 14px;
          text-transform: lowercase;
          max-width: 150px !important;
          height: 30px;
          margin: 0 10px;
        }

        @media screen and (max-width: 370px) {
          font-size: 12px;
          text-transform: lowercase;
          max-width: 100px;
          height: 25px;
          margin: 0 5px;
        }
      }
    }
  }

  &__header {
    background: #5f5353;
    color: $main-white-hovertext;
    cursor: default;
    max-width: 1000px;
    margin: 0 auto;
    border-radius: 5px;
    font-weight: bold;
    font-size: 17px;
    height: 5.5rem;
    overflow: hidden;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
      margin: 0 1em;
    }

    // @media screen and (max-width: 1030px) {
    //   max-width: 700px;
    //   text-transform: lowercase;
    //   margin: 0 1em;
    //   }
    @media screen and (max-width: 770px) {
      max-width: 100%;
      margin: 0 1em;
    }

    @media screen and (max-width: 600px) {
      font-size: 12px;
      text-transform: lowercase;
      letter-spacing: 1px;
      height: 4rem;

      margin: 0 0.2em;
    }

    @media screen and (max-width: 400px) {
      font-size: 11px;
    }

    &-item {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-item:nth-last-of-type(2) {
      flex-basis: 200%;
    }

    &-item:nth-last-of-type(4) {
      flex-basis: 50%;
    }

    &-item:nth-last-of-type(5) {
      display: none;
    }

    &-item:nth-last-of-type(7) {
      flex-basis: 50%;
      
    
    }
    
    .main-header-hide:nth-of-type(1) {
      display: none;
      cursor: default;
    }
  }
  
  &__add {
    position: fixed;
    border: 4px solid $main-brown-font;
    left: 10rem;
    bottom: 5rem;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 35px;
    border-radius: 50%;
    transition: 0.4s;
    animation: move 3s ease infinite;

    @media screen and (max-width: 1700px) {
      right: 10%;
    }

    @media screen and (max-width: 600px) {
      left: 0rem;
      bottom: 0rem;
      margin: 0 0.2em 0.2em;
    }
  }

  &__body {
    ::-webkit-scrollbar {
      width: 0;
      /* background-color: transparent; */
      } 
    overflow: hidden;
    width: 100%;
    margin: 10px auto;
    border-radius: 5px;
    font-weight: bold;
    font-size: 17px;
    display: flex;
    flex-flow: column;
    height: 520px;
    // padding: 0 1em;
    // background: #5f5353;
   
    @media screen and (min-width: 600px) {
      padding: 0 1em;
    }
    @media screen and (min-width: 1000px) {
      // margin: 10px 1em;
      width: 1000px;
      // padding: 0;
      
    }
    @media screen and (min-width: 1300px) {
      // margin: 10px 1em;
      width: 1000px;
      padding: 0;
      
    }

    @media screen and (max-width: 770px) {
      font-size: 14px;
      text-transform: lowercase;
      height: 520px;
      margin: 10px 0.2em;
      
    }

    @media screen and (max-width: 600px) {
      font-size: 14px;
      text-transform: lowercase;
      height: 480px;
      margin: 10px 0.2em;
    }

    .second-mainbar-wrapper__body-wrapper {
      overflow-y: scroll;
      height: 60vh;
      width: 100%;
      // padding-right: 200px;
      transition: 0.5s;
      // background: #5f5353;

      @media screen and (max-width: 1030px) {
        // padding: 0 1em;
        width: 100%;
        // margin-: 70px;

        text-transform: lowercase;
      }

      @media screen and (max-width: 770px) {
        font-size: 14px;
        text-transform: lowercase;


      }

      @media screen and (max-width: 600px) {
        padding: 0 0.2em;
        // padding-right: 30px;
        // margin-right: 20px;
        max-width: 110%;
        margin-left: 0;
        text-transform: lowercase;
        font-size: 12px;

      }

      @media screen and (max-width: 400px) {


        font-size: 12px;
      }
    }

    &-container {
      // margin-bottom in component azmanotlist
      background: $main-white-hovertext;
      position: relative;
      // margin-bottom: 5px;
      width: 100%;
      height: 100%;
      display: flex;
      height: 5rem;
      transition: 0.3s;
      box-shadow: 0px 19px 9px -25px rgb(128, 224, 129);
      overflow-y: visible;
      cursor: grab;
      animation: appear 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
      @keyframes appear {
        0%{
          transform: translateY(-100%);
        }
        100% {
          transform: translateY(0);
        }
      }
      

      .main-header-remove {
        color: red;
        transition: 0.3s;
        cursor: pointer;
      }
    }
    
    &-container:last-child {
      margin-bottom: 0;
    }

    &-container:hover {
      
      background: $main-green;
      color: #f3f5f7;
      .header-item__status {
        color: $main-brown-font;
      }

      .main-header-remove {
        color: red;
      }

      @keyframes rubberBand {
        0% {
          transform: scale(1);
        }

        30% {
          transform: scaleX(1.15) scaleY(0.75);
        }

        40% {
          transform: scaleX(0.75) scaleY(1.25);
        }

        60% {
          transform: scaleX(1.15) scaleY(0.85);
        }

        100% {
          transform: scale(1);
        }
      }

    }

    &-container:last-of-type {
      box-shadow: none;
    }
  }

  &__add:hover {
    border: 4px solid $main-green;
    color: $main-green;
    transform: rotate(180deg) scale(1.2);
    cursor: pointer;
  }
}


@keyframes move {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

