@import '../../../../../../variable/variable.scss';


.delete-modal-selector {
    cursor: default;
    position: absolute;
    background: #5f5353;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    transition: 0.5s ease;
    padding-left: 10px;
    &__edit {
        // flex-basis: 50%;
    }
    button {
        margin: 20px;
        background: none;
        border: none;
        outline: none;
        height: 80%;
        width: 80px;
        @media screen and (max-width: 600px) {
            margin: 0;
            height: 70%;
            margin-right: 20px;
            width: 70px;
            }
        
        div {
         
            height: 100%;
            border-radius: 8px;
            
            // border: 4px solid $main-white-hovertext;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 15px;
            font-weight: 600;
            color: $main-white-hovertext;
            cursor: pointer;

            @media screen and (max-width: 600px) {
                
                font-size: 12px;
                letter-spacing: 0.5px;

                }
        }
        
        // .fa-window-close {
        //     color: $main-green;
        //     font-size: 25px;
        //     cursor: pointer;
        //     @media screen and (max-width: 600px) {
        //         font-size: 20px;

                
        //     }
        // }
        // .fa-window-close:hover {
        //     color: $main-white-hovertext;
        // }
        
        // .fa-check-square {
        //     color: red;
        //     font-size: 25px;
        //     cursor: pointer;
        //     @media screen and (max-width: 600px) {
        //         font-size: 20px;
        //     }
        // }
    }
    &__warning{
        color: $main-brown-font;
        font-size: 15px;
        font-weight: 500;
        @media screen and (max-width: 600px) {
            display: none;
        }
    }
    
}
.delete-modal-selector:hover {
    .fa-window-close {
        color: $main-white-hovertext;
    }
}