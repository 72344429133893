 
.header-sign {
    @media screen and (max-width: 600px) {
        margin-right: 10px;
    }

}
.log-out-btn {
    display: block;
    margin-top: 20px;
}
.fa-sign-out-alt {
    justify-content: center;
    align-items: center;
    font-size: 20px;
    // display: none;
    @media screen and (max-width: 600px) {
        display: flex;
        padding: 2px 20px;
        font-size: 25px;

    }
}
