@import '../../../../../variable/variable.scss';


.modal {
    display: block;
    z-index: 10;
}

.modal-azmanot {
    position: fixed;
    margin: 0;
    
}

.modal-azmanot-wrapper {
    top: 0;
    position: fixed;
    height: 100%;
    transition: 1s ease;
    width: 100vw;
    background: #f2f2f2;
    display: flex;
    flex-flow: column;
    outline: none;
    align-items: center;
    margin-bottom: 2em;

    // @media screen and (max-width: 1020px) {
    //     width: 100vw;

    // }

    .modal-azmanot-wrapper-filterbar {
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;

        input {
            text-align: right;
            max-width: 15rem;
        }
    }

    &-inner:first-child {
        max-height: 8vh;
        background: #5f5353;
        display: flex;
        justify-content: center;
        padding-top: 1rem;
        border-radius: 8px;
        max-width: 1200px;
        overflow: visible;

        @media screen and (max-width: 1020px) {
            width: 100vw;
        }

        @media screen and (max-width: 600px) {
            border-radius: 0;
        }
    }

    &-inner-main {
        height: 100%;
        margin-top: 10px;
        

    }

    &-inner {
        width: 100%;
        display: flex;
        flex-flow: column;
        // justify-content: space-around;
        align-items: center;
        overflow: hidden;
        padding-bottom: 5em;

        &__cards {
            margin-top: 0.5rem;
            display: flex;
            padding-right: 2em;
            padding-bottom: 10rem;
            transform: translateX(20px);
            flex-flow: row-reverse wrap;
            justify-content: center;
            align-items: flex-start;
            overflow-y: auto;
            max-width: 1400px;
            // margin-right: 1em;
            @media screen and (max-width: 600px) {
                padding-right: 0;
                transform: translateX(0);
                max-width: 100vw;

            }

            &-item {
                outline: none;
                margin: 5px;
                width: 350px;
                min-height: 120px;
                position: relative;
                display: flex;
                flex-flow: row-reverse;
                background: #fff;
                border-radius: 5px;
                overflow-y: hidden;
                // transform-style: preserve-3d;
                
                .modal-azmanot-wrapper-inner__cards-item__wrapper {
                    position: relative;
                    width: 70%;
                    
                    
                }
                &__front {
                    // z-index: 3;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    justify-content: space-between;
                    align-items: center;
                    transition: 0.5s;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    overflow: hidden;
                    padding: 0.5em 0;
                    background: #c7f16b;
                    transform-style: preserve-3d;
                    // box-shadow: 11px 11px 11px #cecece;
                    // background: red;
                    // display: none;
                    &-counter {
                        position: absolute;
                        right: 0;
                        background: $main-green;
                        padding: 0.2em 0.5em;
                        border-radius: 5px;
                        display: flex;
                        align-items: center;
                        color: $main-white-hovertext;
                        font-size: 12px;
                    }

                    &-select {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-right: 10px;
                        // background: #5f5353;
                        width: 70%;
                        margin: 0 auto;
                        height: 40%;
                        color: $main-white-hovertext;  
                    }

                }
                .modal-azmanot-wrapper-inner__cards-item__front-logo {
                    
                    // background: url('./goods/orange.png') center center no-repeat ;
                    background-size: contain;
                    // background: red;
                    width: 30%;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;
                    
                }
                &__front:hover {}

                &__back {
                    
                    background: $main-white-hovertext;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    -webkit-transform: rotateY(180deg);
                    -webkit-backface-visibility: hidden;
                    transform: rotateY(180deg);
                    backface-visibility: hidden;
                    transition: 0.5s;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;
                    overflow: hidden;
                    // transform-style: preserve-3d;
                    // box-shadow: 11px 11px 11px #cecece;
                    z-index: 3;
                    &-wrapper {
                        background: #ffbc8f;
                        height: 100%;
                        display: flex;
                        flex-flow: column;
                        justify-content: space-evenly;

                        &-addiction {
                            // margin-top: 10px;
                            height: 33%;
                            display: flex;
                            flex-flow: row;
                            justify-content: space-around;
                            align-items: center;
                            width: 70%;
                            margin: 0 auto;
                            padding-left: 1em;
                            // background: #5f5353;
                            transform: translateX(0.5em);
                            label {
                                width: 10rem;
                                height: 2.5em;
                            }
                            i {
                                color: #5f5353;
                                
                            }
                            &__price {
                                width: 45%;
                                height: 60%;
                                display: flex;
                                flex-flow: row-reverse;
                                justify-content: space-between;
                                align-items: center;

                                span {
                                    // background: $main-white-hovertext;
                                    // width: 75%;
                                    max-width: 2em;
                                    height: 100%;
                                    // margin: 10px;
                                    border-radius: 5px;
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    padding-right: 10px;
                                    color:#fff;

                                }

                                &-inner {
                                    min-width: 3em;
                                    overflow: hidden;
                                    border-radius: 5px;
                                    height: 100%;
                                    
                                    input {
                                        padding: 5px 10px;
                                        outline: none;
                                        background: $main-white-hovertext;
                                        border: none;
                                        height: 100%;
                                        margin-left: 10px;
                                        border-radius: 5px;
                                    }
                                }
                            }

                            &__mlai {
                                width: 45%;
                                height: 60%;
                                display: flex;
                                flex-flow: row-reverse;
                                justify-content: center;
                                align-items: center;
                                // background: chartreuse;

                                span {
                                    // background: $main-white-hovertext;
                                    // width: 75%;
                                    max-width: 2em;
                                    height: 100%;
                                    // margin: 10px;
                                    border-radius: 5px;
                                    display: flex;
                                    justify-content: flex-end;
                                    align-items: center;
                                    padding-right: 10px;
                                    color: #fff;
                                    
                                }

                                &-inner {
                                    min-width: 3em;
                                    overflow: hidden;
                                    border-radius: 5px;
                                    height: 100%;
                                    margin-right: 0.5em;
                                    input {
                                        padding: 10px 10px;
                                        outline: none;
                                        background: $main-white-hovertext;
                                        border: none;
                                        height: 100%;
                                        margin-left: 10px;
                                        border-radius: 5px;
                                        width: 3em;
                                    }
                                }
                            }
                        }

                        &__head {
                            color: $main-white-hovertext;
                        }

                        &__control {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            height: 20%;

                            &-inner {
                                display: flex;
                                flex-flow: row-reverse;
                                width: 100%;
                                height: 100%;
                                align-items: center;
                                justify-content: space-evenly;
                                

                                button {
                                    padding: 0.2em 0.5em;
                                    background: #fff;
                                    border: none;
                                    outline: none;
                                    color: $main-white-hovertext;
                                    font-size: 20px;
                                    border-radius: 5px;
                                    i {
                                        color: #5f5353;
                                    }
                                }

                                .number-input-goods-container {
                                    width: 30%;
                                    overflow: hidden;
                                    height: 75%;
                                    border-radius: 5px;

                                    input {
                                        border-radius: 5px;
                                        height: 100%;
                                        border: none;
                                        padding-left: 5px;
                                        outline: none;
                                        font-size: 18px;
                                    }
                                }
                            }

                            .add-product-btn {
                                cursor: pointer;
                                width: 30%;
                                margin-right: 10px;
                                border-radius: 5px;
                                border: none;
                                height: 50%;
                                text-transform: uppercase;
                                font-family: sans-serif;
                                color: $main-green;
                                font-size: 12px;
                                font-weight: 600;
                                letter-spacing: 0.5px;
                                outline: none;
                                transition: 0.3s;
                            }

                            .add-product-btn:hover {
                                background: $main-green;
                                color: $main-white-hovertext;
                            }
                        }

                        &__boxwrappers {
                            width: 170px;
                            height: 15%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            text-align: right;
                            position: relative;
                            // background: red;
                            input {
                                background: $main-white-hovertext;
                                width: 190px;
                                height: 1.7em;
                                border-radius: 5px;
                                padding: 5px 10px;
                                font-size: 15px;
                                border: none;
                                outline: none;
                                text-align: right;
                                margin-left: 5em;

                                @media screen and (max-width: 600px) {
                                    font-size: 14px;
                                    text-transform: lowercase;
                                }
                            }

                            select {
                                cursor: pointer;
                                background: $main-white-hovertext;
                                min-width: 171px;
                                height: 1.7em;
                                color: $main-brown-font;
                                border-radius: 5px;
                                padding: 5px 10px;
                                font-size: 15px;
                                border: none;
                                outline: none;
                                text-align: right;
                                -webkit-appearance: none;
                                
                            }
                            select:disabled {
                                background: #e1cba2;
                                opacity: 0.7;
                                cursor: default;
                            }
                        }
                    }
                }
            }
        }

        &__container-inc {
            height: 700px;
            display: flex;
            flex-flow: column;
            justify-content: center;
            align-items: center;
            padding-top: 4em;

            &-search {
                position: relative;
                border-radius: 5px;
                background: $main-white-hovertext;
                height: 35px;
                display: flex;
                flex-flow: column;
                margin-bottom: 5px;
                justify-content: start;

                input {
                    height: 35px;
                    outline: none;
                    text-align: end;
                    background: url('./inc/searchmagnifierinterfacesymbol1_79893.svg') no-repeat 7px 11px;
                    background-size: auto 40%;

                }

                .modal-azmanot-wrapper-inner__container-inc-wrapper-list:first-of-type {
                    box-shadow: 5px -17px 0px -16px rgba(214, 214, 214, 1);
                }

                .modal-azmanot-wrapper-inner__container-inc-wrapper-list {
                    transform: translateY(32px);
                    position: absolute;
                    top: 0;
                    max-height: 100px;
                    overflow: hidden;
                    width: 100%;
                    margin: 0 auto;
                    background: $main-white-hovertext;
                    text-align: end;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    z-index: 10;

                    &__item {
                        cursor: pointer;
                        padding: 0 10px;
                        height: 25px;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                        transition: 0.3s;
                        font-size: 13px;
                        font-family: sans-serif;
                    }

                    &__item:hover {
                        background: $main-green;
                    }
                }
            }
        }

        &__container-inc-wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            margin-bottom: 0.01rem;

            &__datepicker {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin: 5px 0;

                span {
                    @media screen and (max-width: 600px) {
                        left: 1em !important;
                    }
                }

                input {
                    background: $main-white-hovertext;
                    max-width: 100%;
                    border-radius: 5px;
                    padding: 5px 10px;
                    font-size: 15px;
                    border: none;
                    outline: none;
                    text-align: right;

                    @media screen and (max-width: 600px) {
                        font-size: 14px;
                        text-transform: lowercase;
                        max-width: 150px;
                    }
                }
                input:disabled{
                    
                    opacity: 1;
                }
            }

            .modal-azmanot-wrapper-inner__cards-item__back-wrapper__checkakpaca {
                display: flex;
                align-items: center;
                flex-flow: row;
                justify-content: space-evenly;
                width: 30%;
                border-radius: 5px;

                span {
                    color: $main-white-hovertext;
                }
            }
        }
    }

    &-inner-control {
        background: #f2f2f2;
        position: fixed;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;

        &__wrapper {
            padding-top: 10px;
            display: flex;
            // background: chocolate;
            width: 230px;
            justify-content: space-evenly;
            height: 100%;
            position: relative;
        }
    }
}

@keyframes animatezoom {
    from {
        transform: scale(1.5)
    }

    to {
        transform: scale(1)
    }
}

.modal-azmanot-wrapper-inner__container-inc-wrapper-list {
    input {
        background: $main-white-hovertext;
        max-width: 100%;
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 15px;
        border: none;
        outline: none;
        text-align: right;

        @media screen and (max-width: 600px) {
            font-size: 14px;
            text-transform: lowercase;
            max-width: 150px;
        }
    }
}