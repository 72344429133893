@import '../../../variable/variable.scss';
@import '../../../variable/variable.scss';

.main-performans {
    padding-bottom: 100px;

    &__wrapper {
        // max-width: 1280px;
        margin: 0 auto;
        overflow: hidden;
        &-conteiner {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            align-items: center;
            max-width: 1400px;
            margin: 0 auto;
            @media screen and( max-width: 1300px) {
                max-width: 670px;
            }
            &__inner {
                position: relative;
                height: 420px;
                width: 300px;
                margin: 1em 0.5em;
                // overflow: hidden;
                cursor: pointer;

                &-front, &-back, &-prior {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: 1s; 
                    backface-visibility: hidden; 
                    border-radius: 10px;
                    box-shadow: 7px 7px 11px #cecece;
                    img {
                        min-width: 300px;
                        // filter: drop-shadow(0 0 0.75rem crimson);
                        // filter: drop-shadow(0 0 0.75rem #4444dd);
                        // filter: drop-shadow(10px 8px 4px #4444dd);
                        animation: colorSwitcher 5s infinite;
                        @keyframes colorSwitcher {
                            0% {
                               -webkit-filter: drop-shadow(0 0 0.75rem #4444dd);
                                filter: drop-shadow(0 0 0.75rem #4444dd);
                            }
                            50% {
                               -webkit-filter: drop-shadow(0 0 0.75rem #000);
                                filter: drop-shadow(0 0 0.75rem #000);
                            }
                            75% {
                               -webkit-filter: drop-shadow(0 0 0.75rem crimson);
                                filter: drop-shadow(0 0 0.75rem crimson);
                            }
                            100% {
                               -webkit-filter: drop-shadow(0 0 0.75rem #4444dd);
                                filter: drop-shadow(0 0 0.75rem #4444dd);
                            }
                        }
                    }
                }
                
                .front-first-img {
                    // background-image: url('./lime.png');
                    background-position: center ;
                    background-color: #c7f16b;
                    // background-blend-mode:luminosity;
                    background-repeat: no-repeat;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-size: contain ;
                    
                   
                }
                .front-second-img {
                    // background-image: url('./mandarin.png');
                    background-position: center;
                    background-color: #ffbc8f;
                    // background-blend-mode: luminosity;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .front-third-img {
                    // background-image: url('./lemon.png') ;
                    background-position: center;
                    background-color: #ffed77;
                    // background-blend-mode: luminosity;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                .front-fourth-img {
                    // background-image: url('./grape1.png') ;
                    background-position: center;
                    background-color: #a6ffd0;
                    background-repeat: no-repeat;
                    // background-blend-mode:darken;
                    background-size: 300px;
                    
                    // filter:contrast();
                }

                &-front {
                    position: relative;
                    overflow: hidden;

                    &__tag {
                        padding: 0.3em 1em;
                        background: rgba(87,61,22,0.7);
                        color: #fff;
                        width: 100%;
                        position: absolute;
                        top: 2rem;
                        right: -6rem;
                        transform: rotateZ(45deg);
                        font-family: sans-serif;
                        font-size: 1.2em;

                    }
                }
                &-back {
                    top: 0;
                    background: #f2f2f2;
                    transform: rotateY(180deg);
                    display: flex;
                    flex-flow: column;
                    overflow: hidden;
                    padding-bottom: 0.5em ;
                    cursor: default;

                    // &__title {
                    //     height: 15%;
                    //     width: 30%;
                    //     font-size: 3em;
                    //     padding: 0.5em 1em;
                    //     background: url('../../../icons/free-icon-horses-races-trophy-36156.svg') center center no-repeat;
                    //     background-size: contain;                       
                    // }

                    &__sub {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 20%;
                        background: $main-green;
                        width: 100%;
                        font-size: 1.3em;
                    }

                    &__inner {
                        height: 90%;
                        width: 100%;

                        &-list{
                            margin: 0.5em 0;
                            height: 100%;
                            width: 100%;
                            display: flex;
                            flex-flow: column;
                            align-items: flex-start;
                            justify-content: center;

                            &__item{
                                font-size: 1em;
                                padding: 0.2em 1em;
                                background: #fff;
                                color: $main-brown-font;
                                width: 65%;
                                margin: 0 auto 0.7rem;
                                border-radius: 8px;
                                text-align: end;
                            }
                        }
                    }

                    &__btn {
                        height: 20%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        z-index: 3;
                        
                        .btn-performance:hover {
                            background: red;
                        }
                    }
                }
            }

            &__inner:hover {
                .main-performans__wrapper-conteiner__inner-front{
                    transform: rotateY(180deg);
                }
                .main-performans__wrapper-conteiner__inner-back{
                    transform: rotateY(360deg);
                }
            }
        }
    }
}