.ref-img {
    // background: url('./SHEMESH.png') center center no-repeat;
    background-size: contain;
    filter: grayscale(100%);
    transition: 0.3s;
}

.ref-img:hover {
    filter: grayscale(0%);
    cursor: pointer;
}

.ref-img1 {
    background: url('./yahon.png') center center no-repeat;
    background-size: contain;
}

.ref-img2 {
    background: url('./fresh.png') center center no-repeat;
    background-size: contain;
}

.ref-img3 {
    background: url('./gadol.png') center center no-repeat;
    background-size: contain;
}

.ref-img4 {
    background: url('./AvivFruit.svg') center center no-repeat;
    background-size: contain;
}

.ref-img5 {
    background: url('./shivok.png') center center no-repeat;
    background-size: contain;
}

.ref-img6 {
    background: url('./tna.png') center center no-repeat;
    background-size: contain;
}

.ref-img7 {
    background: url('./osherad.png') center center no-repeat;
    background-size: contain;
}

.ref-img8 {
    background: url('./8c.png') center center no-repeat;
    background-size: contain;
}

.ref-img9 {
    background: url('./9c.png') center center no-repeat;
    background-size: contain;
}

.ref-img10 {
    background: url('./10c.png') center center no-repeat;
    background-size: contain;
}

.ref-img11 {
    background: url('./11c.jpg') center center no-repeat;
    background-size: contain;
}

.ref-img12 {
    background: url('./tna.png') center center no-repeat;
    background-size: contain;
}

.ref-img13 {
    background: url('./MICHAELI.jpg') center center no-repeat;
    background-size: contain;
}

.ref-img14 {
    background: url('./DAVIDHAI.png') center center no-repeat;
    background-size: contain;
}

.ref-img15 {
    background: url('./15c.png') center center no-repeat;
    background-size: contain;
}

.ref-img16 {
    background: url('./MALE.png') center center no-repeat;
    background-size: contain;
}

.ref-img17 {
    background: url('./17c.png') center center no-repeat;
    background-size: contain;
}

.ref-img18 {
    background: url('./18c.jpg') center center no-repeat;
    background-size: contain;
}

.ref-img19 {
    background: url('./19c.png') center center no-repeat;
    background-size: contain;
}

.ref-img20 {
    background: url('./20c.jpg') center center no-repeat;
    background-size: contain;
}

.ref-img21 {
    background: url('./EINAV.png') center center no-repeat;
    background-size: contain;
}

.ref-img22 {
    background: url('./22c.jpg') center center no-repeat;
    background-size: contain;
}